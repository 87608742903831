import { Form, useForm, useFormState } from 'react-final-form'

import React from 'react'
import moment from "moment";
import { useRouter } from 'next/router'
import { useSearchContext } from './context'

const parseDate = raw => moment(raw, 'YYYY-MM-DD');
const parseTime = raw => moment(decodeURIComponent(raw), 'HH:mm');

function SearchBoxWrapperInner({ children }) {
    const { query } = useRouter();
    const form = useForm();
    const { values } = useFormState();

    // if the querystring changes, update the form
    React.useEffect(() => {
        const { airport, arrivalDate, arrivalTime, departureDate, departureTime } = query;
        form.batch(() => {
            form.change('airport', airport);
            if (arrivalDate) form.change('arrivalDate', parseDate(arrivalDate));
            if (arrivalTime) form.change('arrivalTime', parseTime(arrivalTime));
            if (departureDate) form.change('departureDate', parseDate(departureDate));
            if (departureTime) form.change('departureTime', parseTime(departureTime));
        });
    }, [form, query, query.airport, query.arrivalDate, query.arrivalTime, query.departureDate, query.departureTime]);

    const defaultArrivalDate = React.useMemo(() => moment().add(1, 'days').hour(12).minutes(0).seconds(0).milliseconds(0), []);
    const defaultDepartureDate = React.useMemo(() => defaultArrivalDate.add(1, 'days'), [defaultArrivalDate]);

    return (
        <>{children}</>
    );
}

export default function SearchBoxFormContext({ children }) {
    const { onSearchChange } = useSearchContext();

    const onSubmit = React.useCallback((values) => {
        const { airport, ...other } = values;

        onSearchChange({
            airport,
            arrivalDate: other.arrivalDate.format("YYYY-MM-DD"),
            arrivalTime: other.arrivalTime.format("HH:mm"),
            departureDate: other.departureDate.format("YYYY-MM-DD"),
            departureTime: other.departureTime.format("HH:mm"),
        }, { fullRefresh: true });
    }, [onSearchChange]);

    return (
        <Form onSubmit={onSubmit}>
            {() => <SearchBoxWrapperInner>{children}</SearchBoxWrapperInner>}
        </Form>
    )
}
